const colors = {
  bg: '#1f1f38',
  bg_variant: '#2c2c6c',
  primary: '#4db5ff',
  primary_variant: 'rgba(77, 181, 255, 0.4)',
  white: '#fff',
  light: 'rgba(255, 255, 255, 0.6)',
}

export default colors
