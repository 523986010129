import styled from 'styled-components'
import colors from '../../utils/style/colors'
import animation from '../../utils/style/animation'
import size from '../../utils/style/size'
import { Container, StyledExternalLink } from '../../utils/style/atom'
import { MdOutlineEmail } from 'react-icons/md'
import { RiMessengerLine } from 'react-icons/ri'
import { BsWhatsapp } from 'react-icons/bs'

export const ContactContainer = styled(Container)`
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;

    /* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
    @media screen  and (max-width: 1024px) {        
        grid-template-columns: 1fr;
        gap: 2rem;        
    }

    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {        
        width: ${size.container_width_sm};       
    }
`
export const ContactOptions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
`
export const ContactOption = styled.article`
    background: ${colors.bg_variant};
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: ${animation.transition};

    &:hover {
        background: transparent;
        border-color: ${colors.primary_variant};
    }
`
export const IconEmail = styled(MdOutlineEmail)`
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
`
export const IconMessenger = styled(RiMessengerLine)`
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
`
export const IconWhatsapp = styled(BsWhatsapp)`
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
`
export const ContactOptionLink = styled(StyledExternalLink)`
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
`

/* ============== FORM ============== */
export const ContactForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
`
export const ContactInput = styled.input`
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid ${colors.primary_variant};
    resize: none;
    color: ${colors.white};
`
export const ContactTextarea = styled.textarea`
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid ${colors.primary_variant};
    resize: none;
    color: ${colors.white};
`