import { createSlice } from '@reduxjs/toolkit'

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    menuActive: '#home',
  },
  reducers: {
    setActiveMenu: (state, { payload }) => {
      state.menuActive = payload
    },
  },
})

export const { setActiveMenu } = menuSlice.actions
export default menuSlice.reducer
