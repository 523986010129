import styled from 'styled-components'
import { Container } from '../../utils/style/atom'
import colors from '../../utils/style/colors'
import animation from '../../utils/style/animation'
import { BiCheck } from 'react-icons/bi'

export const ServiceContainer = styled(Container)`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;

    /* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
    @media screen  and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }

    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
`
export const ServiceSubContainer = styled.article`
    background: ${colors.bg_variant};
    border-radius: 0 0 2rem 2rem;
    border: 1px solid ${colors.primary};
    height: fit-content;
    transform: ${animation.transition};

    &:hover{
        background: transparent;
        border-color: ${colors.primary_variant};
        cursor: default;
    }

    /* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
    @media screen  and (max-width: 1024px) {
        height: auto;
    }
`
export const ServiceHead = styled.div`
    background: ${colors.primary};
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
`
export const ServiceTitle = styled.h3`
    color: ${colors.bg};
    font-size: 1rem;
    text-align: center;
`
export const ServiceList = styled.ul`
    padding: 2rem;
`
export const ServiceListItem = styled.li`
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
`
export const ServiceListIcon = styled(BiCheck)`
    color: ${colors.primary};
    margin-top: 2px;
`
export const ServiceListText = styled.p`
    font-size: 0.9rem;
`