import React from 'react'
import Nav from '../../components/nav/Nav'
import About from '../../components/about/About'
import Experience from '../../components/experience/Experience'
import Services from '../../components/services/Services'
import Portfolio from '../../components/portfolio/Portfolio'
import Testimonials from '../../components/testimonials/Testimonials'
import Contact from '../../components/contact/Contact'

function index() {
  return (
    <>
        <Nav />
        <About />
        <Experience />
        <Services />
        <Portfolio />
        <Testimonials />
        <Contact />
    </>
  )
}

export default index