import React from 'react'
import { useDispatch } from 'react-redux'
import { setActiveMenu } from '../../features/menu.slice'
import { 
  AboutContainer, 
  AboutMe, 
  AboutMeImg, 
  AboutContent, 
  AboutCards, 
  AboutCard, 
  AboutCarIconFaAward, 
  // AboutCarIconFiUsers, 
  AboutCarIconVscFolderLibrary,
  AboutCardTitle,
  AboutCardText,
  AboutContentText 
} from './styles'
import { ButtonPrimary } from '../../utils/style/atom'
import ME from '../../assets/me-about.jpg'


const About = () => {
  const dispatch = useDispatch()
  return (
    <section id="about">
      <h5>Faisons connaissance</h5>
      <h2>À propos de moi</h2>

      <AboutContainer>
        <AboutMe>
          <AboutMeImg>
            <img src={ME} alt="À propos de moi" />
          </AboutMeImg>
        </AboutMe>
        <AboutContent>
          <AboutCards>

            <AboutCard>
              <AboutCarIconFaAward/>
              <AboutCardTitle>Experience</AboutCardTitle>
              <AboutCardText>
                15 ans <br />  
                Informatique & NTIC
              </AboutCardText>
            </AboutCard>

            {/* <AboutCard>
              <AboutCarIconFiUsers/>
              <AboutCardTitle>Clients</AboutCardTitle>
              <AboutCardText>...</AboutCardText>
            </AboutCard> */}

            <AboutCard>
              <AboutCarIconVscFolderLibrary/>
              <AboutCardTitle>Projets</AboutCardTitle>
              <AboutCardText>7 Projets completés</AboutCardText>
            </AboutCard>        
          </AboutCards>
          <AboutContentText>
            Doté d'une solide expérience dans l'informatique et NTIC, 
            j'ai décidé de faire de ma passion du développement web frontend et backend, mon avenir professionnel. 
            Sorti de formation développeur web je recherche une expérience professionnel dans ce domaine de préférence à distance (télétravail). 
            Je suis également en recherche d'un contrat en alternance pour développer mes compétences 
            et intégrer un parcours de Développeur d'application - JavaScript React.
          </AboutContentText>

          <ButtonPrimary to="#contact" onClick={()=>dispatch(setActiveMenu('#contact'))}>Discutons !</ButtonPrimary>
        </AboutContent>
      </AboutContainer>
    </section>
  )
}

export default About