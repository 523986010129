import styled from 'styled-components'
import { HashLink as Link} from 'react-router-hash-link';
import colors from './colors'
import animation from './animation'
import size from './size'

export const Container = styled.div `
    width: ${size.container_width_lg};
    margin: 0 auto;

    /* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
    @media screen  and (max-width: 1024px) {
        width: ${size.container_width_md};
    }
    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        width: ${size.container_width_sm};
    }
`
export const StyledLink = styled(Link)`
    color: ${colors.primary};
    transition: ${animation.transition};

    &:hover {
        color: ${colors.white};
    }
`
export const StyledExternalLink = styled.a`
    color: ${colors.primary};
    transition: ${animation.transition};

    &:hover {
        color: ${colors.white};
    }
`

export const Button = styled(Link)`
    width: max-content;
    display: inline-block;
    color: ${colors.primary};
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid ${colors.primary};
    transition: ${animation.transition};

    &:hover {
        background: ${colors.white};
        color: ${colors.bg};
        border-color: transparent;
    }
`
export const ButtonPrimary = styled(Button)`
    background: ${colors.primary};
    color: ${colors.bg};
`
export const ButtonExt = styled(StyledExternalLink)`
    width: max-content;
    display: inline-block;
    color: ${colors.primary};
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid ${colors.primary};
    transition: ${animation.transition};

    &:hover {
        background: ${colors.white};
        color: ${colors.bg};
        border-color: transparent;
    }
`
export const ButtonPrimaryExt = styled(ButtonExt)`
    background: ${colors.primary};
    color: ${colors.bg};
`