import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import { StyledGlobalStyle } from './utils/style/StyledGlobalStyle'
import Home from './pages/Home/'
import { Provider } from 'react-redux'
import store from './utils/store'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <StyledGlobalStyle />
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </React.StrictMode>
  </Provider>
)
