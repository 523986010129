import React from 'react'
import { FaFacebookF } from 'react-icons/fa'
import { FiInstagram } from 'react-icons/fi'
import { IoLogoTwitter } from 'react-icons/io'
import { 
  FooterContainer,
  FooterLink,
  FooterLogo,
  FooterPermalinks,
  FooterSocials,
  FooterSocialsLink,
  FooterCopyright
} from './styles'
import { useDispatch } from 'react-redux'
import { setActiveMenu } from '../../features/menu.slice'

const Footer = () => {
  const dispatch = useDispatch()
  return (
    <FooterContainer role='contentinfo'>
      <FooterLogo to="#home">PORTELAS</FooterLogo>

      <nav role='navigation' aria-label='Navigation footer'>
        <FooterPermalinks>
          <li><FooterLink to="#home" onClick={()=>dispatch(setActiveMenu('#home'))} aria-label="Lien vers Accueil">Accueil</FooterLink></li>
          <li><FooterLink to="#about" onClick={()=>dispatch(setActiveMenu('#about'))} aria-label="Lien vers section about">À propos</FooterLink></li>
          <li><FooterLink to="#experience" onClick={()=>dispatch(setActiveMenu('#experience'))} aria-label="Lien vers section experience">Mes Compétences</FooterLink></li>
          <li><FooterLink to="#services" onClick={()=>dispatch(setActiveMenu('#services'))} aria-label="Lien vers section service">Mes services</FooterLink></li>
          <li><FooterLink to="#portfolio" onClick={()=>dispatch(setActiveMenu('#portfolio'))} aria-label="Lien vers section portfolio">Portfolio</FooterLink></li>
          <li><FooterLink to="#testimonials">Témoignage</FooterLink></li>
          <li><FooterLink to="#contact" onClick={()=>dispatch(setActiveMenu('#contact'))} aria-label="Lien vers section contact">Contact</FooterLink></li>
        </FooterPermalinks>
      </nav>

      <nav role='navigation' aria-label='Navigation réseaux sociaux footer'>
        <FooterSocials>
          <FooterSocialsLink href="https://www.facebook.com/michael.portelas" target='_blank' rel="noreferrer" aria-label="Lien vers profile Facebook"><FaFacebookF /></FooterSocialsLink>
          <FooterSocialsLink href="https://instagram.com/" target='_blank' rel="noreferrer" aria-label="Lien vers profile Instagram"><FiInstagram /></FooterSocialsLink>
          <FooterSocialsLink href="https://twitter.com/" target='_blank' rel="noreferrer" aria-label="Lien vers profile Twitter"><IoLogoTwitter /></FooterSocialsLink>
        </FooterSocials>
      </nav>

      <FooterCopyright>
        <small>&copy; PORTELAS. All rights reserved.</small>
      </FooterCopyright>
    </FooterContainer>
  )
}

export default Footer