import React from 'react'

import { 
  ServiceContainer, 
  ServiceSubContainer, 
  ServiceHead, 
  ServiceTitle, 
  ServiceList, 
  ServiceListItem,
  ServiceListIcon,
  ServiceListText
} from './styles'

const Services = () => {
  return (
    <section id="services">
      <h5>Ce que j'offre</h5>
      <h2>Mon Savoir Faire</h2>

      <ServiceContainer>
        {/* <ServiceSubContainer>
          <ServiceHead>
            <ServiceTitle>UI/UX Design</ServiceTitle>
          </ServiceHead>
          <ServiceList>
            <ServiceListItem>
              <ServiceListIcon/>
              <ServiceListText>Lorem, ipsum dolor sit amet consectetur elit.</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Lorem, ipsum dolor sit amet consectetur elit.</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Lorem, ipsum dolor sit amet consectetur elit.</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Lorem, ipsum dolor sit amet consectetur elit.</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Lorem, ipsum dolor sit amet consectetur elit.</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Lorem, ipsum dolor sit amet consectetur elit.</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Lorem, ipsum dolor sit amet consectetur elit.</ServiceListText>
            </ServiceListItem>
          </ServiceList>
        </ServiceSubContainer> */}
        {/* END OF UI/UX */}

        <ServiceSubContainer>
          <ServiceHead>
            <ServiceTitle>Développement Frontend</ServiceTitle>
          </ServiceHead>
          <ServiceList>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Créer une application React et implémenter ses routes avec React-Router</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Construction de site web responsive avec Bootstrap </ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Implémenter des animations complexes et modernes avec SASS</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Dynamiser une page web avec des animations CSS</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Implémenter un site en Javascript avec l'utilisation d'API</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Optimiser le SEO et l'accessibilité d'un site web</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Créer un template HTML/CSS depuis une maquette d'un UX-UI Designer </ServiceListText>
            </ServiceListItem>
          </ServiceList>
        </ServiceSubContainer>
        {/* END OF WEBDEV */}

        <ServiceSubContainer>
          <ServiceHead>
            <ServiceTitle>Développement Backend</ServiceTitle>
          </ServiceHead>
          <ServiceList>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Créer un serveur web simple avec Express.</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Créez une API REST avec Node, Express et MongoDB.</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Créer un système d'authentification avec Express - Bcrypt</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Créer un system de gestion de fichiers avec Multer</ServiceListText>
            </ServiceListItem>
            <ServiceListItem>
              <ServiceListIcon />
              <ServiceListText>Implementer un middleware d'authentification avec Json Web Token</ServiceListText>
            </ServiceListItem>            
          </ServiceList>
        </ServiceSubContainer>
        {/* END OF CONTENT */}


      </ServiceContainer>
    </section>
  )
}

export default Services