import styled from 'styled-components'
import { Container, StyledLink } from '../../utils/style/atom'
import colors from '../../utils/style/colors'

export const HeaderMainContainer = styled.header `
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;

    /* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
    @media screen  and (max-width: 1024px) {
        height: 68vh;
    }
    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px){
        height: 100vh;
    }
`
export const HeaderContainer = styled(Container) `
    text-align: center;
    height: 100%;
    position: relative;
`
export const TitleLight = styled.h5 `
    color: ${colors.light};
`
export const Me = styled.div `
    background: linear-gradient(${colors.primary}, transparent);
    width: 22rem;
    height: 23rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 5rem 0.5rem 1.5rem 0.5rem;
`
export const ScrollDown = styled(StyledLink) `
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;

    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        display: none;
    }
`
export const Cta = styled.div `
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
`
export const SocialsHeader = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;

    &::after {
        content: "";
        width: 1px;
        height: 2rem;
        background: ${colors.primary};
    }

    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        display: none;
    }
`