import React from 'react'
import { 
  ExperienceContainer, 
  ExperienceSubContainer,
  ExperienceSubContainerTitle,
  ExperienceContent,
  ExperienceDetail,
  SiHtml5,
  SiCss3,
  SiJavascript,
  SiBootstrap,
  SiReact,
  SiRedux,
  SiNodedotjs,
  SiMongodb,
  SiPhp,
  SiMysql,
  SiExpress,
  ExperienceDesc
} from './styles'

const Experience = () => {
  return (
    <section id="experience">
      <h5>Quelle sont mes Compétences</h5>
      <h2>Mes Compétences</h2>

      <ExperienceContainer>
        <ExperienceSubContainer>
          <ExperienceSubContainerTitle>Développement Frontend</ExperienceSubContainerTitle>
          <ExperienceContent>
            <ExperienceDetail>
              <SiHtml5/>
              <div>
                <h4>HTML 5</h4>
                <ExperienceDesc>Confirmé</ExperienceDesc>
              </div>
            </ExperienceDetail>
            <ExperienceDetail>
              <SiCss3 />
              <div>
                <h4>CSS 3</h4>
                <ExperienceDesc>intermediaire</ExperienceDesc>
              </div>
            </ExperienceDetail>
            <ExperienceDetail>
              <SiJavascript />
              <div>
                <h4>JavaScript</h4>
                <ExperienceDesc>intermediaire</ExperienceDesc>
              </div>
            </ExperienceDetail>
            <ExperienceDetail>
              <SiBootstrap />
              <div>
                <h4>Bootstrap 5</h4>
                <ExperienceDesc>Confirmé</ExperienceDesc>
              </div>
            </ExperienceDetail>
            <ExperienceDetail>
              <SiReact />
              <div>
                <h4>React</h4>
                <ExperienceDesc>intermediaire</ExperienceDesc>
              </div>
            </ExperienceDetail>
            <ExperienceDetail>
              <SiRedux />
              <div>
                <h4>Redux</h4>
                <ExperienceDesc>intermediaire</ExperienceDesc>
              </div>
            </ExperienceDetail>
          </ExperienceContent>
        </ExperienceSubContainer>
        {/* END OF FRONTEND */}

        <ExperienceSubContainer>
          <ExperienceSubContainerTitle>Développement Backend</ExperienceSubContainerTitle>
          <ExperienceContent>
            <ExperienceDetail>
              <SiNodedotjs />
              <div>
                <h4>Node JS</h4>
                <ExperienceDesc>intermediaire</ExperienceDesc>
              </div>
            </ExperienceDetail>
            <ExperienceDetail>
              <SiMongodb />
              <div>                
                <h4>MongoDB</h4>
                <ExperienceDesc>Confirmé</ExperienceDesc>
              </div>
            </ExperienceDetail>
            <ExperienceDetail>
              <SiPhp />
              <div>
                <h4>PHP 7-8</h4>
                <ExperienceDesc>intermediaire</ExperienceDesc>
              </div>
            </ExperienceDetail>
            <ExperienceDetail>
              <SiMysql />
              <div>
                <h4>MySQL</h4>
                <ExperienceDesc>Confirmé</ExperienceDesc>
              </div>
            </ExperienceDetail>
            <ExperienceDetail>
              <SiExpress />
              <div>
                <h4>Express</h4>
                <ExperienceDesc>intermediaire</ExperienceDesc>
              </div>
            </ExperienceDetail>
          </ExperienceContent>
        </ExperienceSubContainer>
      </ExperienceContainer>
    </section>
  )
}

export default Experience