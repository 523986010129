import React from 'react'
import { AiOutlineHome, AiOutlineUser } from 'react-icons/ai'
import { BiBook, BiMessageSquareDetail } from 'react-icons/bi'
import { BsImages } from 'react-icons/bs'
import { RiServiceLine } from 'react-icons/ri'
import { NavMainContainer, StyledNavLink, StyledNavLinkActive } from './styles'

import { useSelector, useDispatch } from 'react-redux'
import { setActiveMenu } from '../../features/menu.slice'
import { selectActiveMenu } from '../../utils/selector'

const Nav = () => {
  const dispatch = useDispatch()
  const activeMenu = useSelector(selectActiveMenu)
  
  return (    
    <NavMainContainer role='navigation' aria-label='navigation principal'>
      {activeMenu === '#home' ? (
        <StyledNavLinkActive to="#home" onClick={()=>dispatch(setActiveMenu('#home'))} aria-label="Lien vers Accueil"><AiOutlineHome/></StyledNavLinkActive>
      ) : (
        <StyledNavLink to="#home" onClick={()=>dispatch(setActiveMenu('#home'))} aria-label="Lien vers Accueil"><AiOutlineHome/></StyledNavLink>      
      )}

      {activeMenu === '#about' ? (
        <StyledNavLinkActive to="#about" onClick={()=>dispatch(setActiveMenu('#about'))} aria-label="Lien vers section about"><AiOutlineUser/></StyledNavLinkActive>
      ) : (
        <StyledNavLink to="#about" onClick={()=>dispatch(setActiveMenu('#about'))} aria-label="Lien vers section about"><AiOutlineUser/></StyledNavLink>      
      )}
      
      {activeMenu === '#experience' ? (
        <StyledNavLinkActive to="#experience" onClick={()=>dispatch(setActiveMenu('#experience'))} aria-label="Lien vers section experience"><BiBook/></StyledNavLinkActive>
      ) : (
        <StyledNavLink to="#experience" onClick={()=>dispatch(setActiveMenu('#experience'))} aria-label="Lien vers section experience"><BiBook/></StyledNavLink>      
      )}

      {activeMenu === '#services' ? (
        <StyledNavLinkActive to="#services" onClick={()=>dispatch(setActiveMenu('#services'))} aria-label="Lien vers section service"><RiServiceLine/></StyledNavLinkActive>
      ) : (
        <StyledNavLink to="#services" onClick={()=>dispatch(setActiveMenu('#services'))} aria-label="Lien vers section service"><RiServiceLine/></StyledNavLink>      
      )}

      {activeMenu === '#portfolio' ? (
        <StyledNavLinkActive to="#portfolio" onClick={()=>dispatch(setActiveMenu('#portfolio'))} aria-label="Lien vers section portfolio"><BsImages/></StyledNavLinkActive>
      ) : (
        <StyledNavLink to="#portfolio" onClick={()=>dispatch(setActiveMenu('#portfolio'))} aria-label="Lien vers section portfolio"><BsImages/></StyledNavLink>      
      )}

      {activeMenu === '#contact' ? (
        <StyledNavLinkActive to="#contact" onClick={()=>dispatch(setActiveMenu('#contact'))} aria-label="Lien vers section contact"><BiMessageSquareDetail/></StyledNavLinkActive>
      ) : (
        <StyledNavLink to="#contact" onClick={()=>dispatch(setActiveMenu('#contact'))} aria-label="Lien vers section contact"><BiMessageSquareDetail/></StyledNavLink>      
      )}
    </NavMainContainer>
  )
}

export default Nav