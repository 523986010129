import React, { useRef } from 'react'
import emailjs from 'emailjs-com'
import { 
  ContactContainer,
  ContactOptions,
  ContactOption,
  IconEmail,
  IconMessenger,
  IconWhatsapp,
  ContactOptionLink,
  ContactForm,
  ContactInput,
  ContactTextarea
} from './styles'
import { ButtonPrimary } from '../../utils/style/atom'

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9gy3za5', 'template_1szyjl2', form.current, 'ch4waIUIXWs3poV5V')
      .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
    
    e.target.reset();   
  };

  return (
    <section id="contact">
      <h5>Entrer en contact</h5>
      <h2>Contactez moi</h2>

      <ContactContainer>
        <ContactOptions>
          <ContactOption>
            <IconEmail />
            <h4>Email</h4>
            <h5>michael@portelas.fr</h5>
            <ContactOptionLink href="mailto:michael@portelas.fr" target="_blank" rel="noreferrer">Envoyer un message</ContactOptionLink>
          </ContactOption>
          <ContactOption>
            <IconMessenger />
            <h4>Messenger</h4>
            <h5>michael.portelas</h5>
            <ContactOptionLink href="https://m.me/michael.portelas" target="_blank" rel="noreferrer">Envoyer un message</ContactOptionLink>
          </ContactOption>
          <ContactOption>
            <IconWhatsapp />
            <h4>WhatsApp</h4>
            <h5>+33613729610</h5>
            <ContactOptionLink href="https://wa.me/+33613729610" target="_blank" rel="noreferrer">Envoyer un message</ContactOptionLink>
          </ContactOption>
        </ContactOptions>
        {/* END CONTACT OPTIONS */}
        <ContactForm ref={form} onSubmit={sendEmail} aria-label='Contactez Moi'>
          <ContactInput type="text" name='name' placeholder='Votre Nom Complet' required aria-label='Votre Nom'/>
          <ContactInput type="email" name='email' placeholder='Votre email' required aria-label='Votre email'/>
          <ContactTextarea name="messsage" rows="7" placeholder='Votre Message' required aria-label='Votre message'></ContactTextarea>
          <ButtonPrimary type="submit">Envoyer un message</ButtonPrimary>
        </ContactForm>
      </ContactContainer>
    </section>
  )
}

export default Contact