import React from 'react'
import CV from '../../assets/CV_Portelas_Michael.pdf'

import { Cta } from './styles'
import { Button, ButtonPrimary } from '../../utils/style/atom'

import { useDispatch } from 'react-redux'
import { setActiveMenu } from '../../features/menu.slice'

const CTA = () => {
  const dispatch = useDispatch()
  return (
    <Cta>
        <Button to={CV} target="_blank" download>Télécharger mon CV</Button>
        <ButtonPrimary to="#contact" onClick={()=>dispatch(setActiveMenu('#contact'))}>Discutons !</ButtonPrimary>
    </Cta>
  )
}

export default CTA