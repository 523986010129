import React from 'react'
import CTA from './CTA'
import ME from '../../assets/me.png'
import HeaderSocials from './HeaderSocials'
import { HeaderMainContainer, HeaderContainer, TitleLight, Me, ScrollDown } from './styles'

const Header = () => {
  return (
    <HeaderMainContainer id='home' role='banner'>
      <HeaderContainer>
        <h5>Bonjour je suis </h5>
        <h1>Michael Portelas</h1>
        <TitleLight>Développeur Fullstack</TitleLight>
        <CTA />
        <HeaderSocials />

        <Me>
          <img src={ME} alt="Michael Portelas" />
        </Me>

        <ScrollDown to="#contact" >Scroll Down</ScrollDown>
      </HeaderContainer>
    </HeaderMainContainer>
  )
}

export default Header