import styled from 'styled-components'
import { Container } from '../../utils/style/atom'
import colors from '../../utils/style/colors'
import animation from '../../utils/style/animation'
import { FaAward } from 'react-icons/fa'
import { FiUsers } from 'react-icons/fi'
import { VscFolderLibrary } from 'react-icons/vsc'

export const AboutContainer = styled(Container) `
    display: grid;
    grid-template-columns: 35% 50%;
    gap: 15%;

    /* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
    @media screen  and (max-width: 1024px) {
        grid-template-columns: 1fr;
        gap: 0;
    }
`
export const AboutMe = styled.div`
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(
        45deg,
        transparent,
        ${colors.primary},
        transparent
    );
    display: grid;
    place-items: center;

    /* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
    @media screen  and (max-width: 1024px) {
        width: 50%;
        margin: 2rem auto 4rem;
    }

    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        width: 65%;
        margin: 0 auto 3rem;
    }
`
export const AboutMeImg = styled.div`
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: ${animation.transition};

    &:hover {
        transform: rotate(0);
    }
`
export const AboutContent = styled.div`
    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        text-align: center;
    }
`
export const AboutContentText = styled.p`
    margin: 2rem 0 2.6rem;
    color: ${colors.light};

    /* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
    @media screen  and (max-width: 1024px) {
        margin: 1rem 0 1.5rem;
    }

    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        margin: 1.5rem 0;
    }
`
export const AboutCards = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;

    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
`
export const AboutCard = styled.article`
    background: ${colors.bg_variant};
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: ${animation.transition};

    &:hover {
        background: transparent;
        border-color: ${colors.primary_variant};
        cursor: default;
    }
`
export const AboutCarIconFaAward = styled(FaAward)`
    color: ${colors.primary};
    font-size: 1.4rem;
    margin-bottom: 1rem;
`
export const AboutCarIconFiUsers = styled(FiUsers)`
    color: ${colors.primary};
    font-size: 1.4rem;
    margin-bottom: 1rem;
`
export const AboutCarIconVscFolderLibrary = styled(VscFolderLibrary)`
    color: ${colors.primary};
    font-size: 1.4rem;
    margin-bottom: 1rem;
`
export const AboutCardTitle = styled.h5`
    font-size: 0.95rem;
`
export const AboutCardText = styled.small`
    font-size: 0.7rem;
    color: ${colors.light};
`