import { createGlobalStyle }  from 'styled-components'
import colors from './colors'

export const StyledGlobalStyle = createGlobalStyle `
    * {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        box-sizing: border-box;
        list-style: none;
        text-decoration: none;
    }

    html {
        scroll-behavior: smooth;
    }
    
    ::-webkit-scrollbar {
        display: none;
    }
    
    body {
        font-family: 'Poppins', sans-serif;
        background: ${colors.bg};
        color: ${colors.white};
        line-height: 1.7;
        background-image: url(../src/assets/bg-texture.png);
    }
    
    /* ============== GENERAL STYLES ============== */
    h1, 
    h2, 
    h3, 
    h4, 
    h5 {
        font-weight: 500;
    }
    
    h1 {
        font-size: 2.5rem;
    }
    
    section {
        margin-top: 8rem;
    }
    
    section > h2, 
    section > h5 {
        text-align: center;
        color: ${colors.light};
    }
    
    section > h2 {
        color: ${colors.primary};
        margin-bottom: 3rem;
    }    
       
    img {
        display: block;
        width: 100%;
        object-fit: cover;
    }

    .swiper-pagination-clickable .swiper-pagination-bullet {
        background: ${colors.primary};
    }
    
    /* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
    @media screen  and (max-width: 1024px) {
        section {
            margin-top: 6rem;
        }
    }
    
    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        section > h2 {
            margin-bottom: 2rem;
        }
    }
`