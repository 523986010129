import styled from 'styled-components'
import { Container } from '../../utils/style/atom'
import colors from '../../utils/style/colors'
import animation from '../../utils/style/animation'
import * as icon from 'react-icons/si'

export const ExperienceContainer = styled(Container)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;

    /* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
    @media screen  and (max-width: 1024px) {
        grid-template-columns: 1fr;
    }

    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        gap: 1rem;
    }
`
export const ExperienceSubContainer = styled.div`
    background: ${colors.bg_variant};
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: ${animation.transition};

    &:hover {
        background: transparent;
        border-color: ${colors.primary_variant};
        cursor: default;
    }

    /* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
    @media screen  and (max-width: 1024px) {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }

    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        width: 100%;
        padding: 2rem 1rem;
    }
`
export const ExperienceSubContainerTitle = styled.h3`
    text-align: center;
    margin-bottom: 2rem;
    color: ${colors.primary};
`
export const ExperienceContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;

    /* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
    @media screen  and (max-width: 1024px) {
        padding: 1rem;
    }
`
export const ExperienceDetail = styled.article`
    display: flex;
    gap: 1rem;
`
export const SiHtml5 = styled(icon.SiHtml5)`
    margin-top: 6px;
    color: ${colors.primary};
`
export const SiCss3 = styled(icon.SiCss3)`
margin-top: 6px;
color: ${colors.primary};
`
export const SiJavascript = styled(icon.SiJavascript)`
margin-top: 6px;
color: ${colors.primary};
`
export const SiBootstrap = styled(icon.SiBootstrap)`
margin-top: 6px;
color: ${colors.primary};
`
export const SiReact = styled(icon.SiReact)`
margin-top: 6px;
color: ${colors.primary};
`
export const SiRedux = styled(icon.SiRedux)`
margin-top: 6px;
color: ${colors.primary};
`
export const SiNodedotjs = styled(icon.SiNodedotjs)`
margin-top: 6px;
color: ${colors.primary};
`
export const SiMongodb = styled(icon.SiMongodb)`
margin-top: 6px;
color: ${colors.primary};
`
export const SiPhp = styled(icon.SiPhp)`
margin-top: 6px;
color: ${colors.primary};
`
export const SiMysql = styled(icon.SiMysql)`
margin-top: 6px;
color: ${colors.primary};
`
export const SiExpress = styled(icon.SiExpress)`
margin-top: 6px;
color: ${colors.primary};
`
export const ExperienceDesc = styled.small`
    color: ${colors.light};
`