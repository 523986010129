import React from 'react'
import AVTR1 from '../../assets/avatars/stephane_brunie.jpg'
import AVTR2 from '../../assets/avatars/hamza_fahi.jpg'
import AVTR3 from '../../assets/avatars/maxime_chalas.jpg'
import AVTR4 from '../../assets/avatars/roland_niokhor_faye.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';
import { 
  TestimonialContainer,
  SlideTestimonial,
  ClientAvatar,
  ClientReview,
  ClientName
} from './styles'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar: AVTR1,
    name: 'Stephane BRUNIE (Ancien éleve Optitek)',
    review: `
      J'ai apprécié les vidéos de vos interventions, clarté de langage, explication de l'organisation du chantier.
      Des vidéos complètes à mon goût.
      C'est mon aparté pour cette journée.
      Bonne journée.
    `
  },
  {
    avatar: AVTR2,
    name: 'Hamza Fahi (Mentor O.C Projet-4)',
    review: `
      Bonne présentation, dans les limites de temps accordées. 
      Les différents livrables sont complets et pertinents, respectant les différents critères. 
      Félicitations et bon courage!!
    `
  },
  {
    avatar: AVTR3,
    name: 'Maxime Chalas (Mentor O.C Projet-5)',
    review: `Bon temps de parole, une présentation propre avec une bonne élocution pour la présentation du projet, les réponses aux questions sont bonnes. Javascript bien appréhendé et compris dans l'ensemble. Félicitation continuez comme ça sur les prochains projets !`
  },
  {
    avatar: AVTR4,
    name: 'Roland Niokhor Faye (Mentor O.C Projet-7)',
    review: ` Excellente soutenance, la posture était professionnelle, les explications claires et le temps imparti assez bien respecté. Projet validé. Félicitation!`
  },
]

const Testimonial = () => {
  return (
    <section id="testimonial">
      <h5>Avis des clients</h5>
      <h2>Témoignage</h2>
      <TestimonialContainer
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}        
      >
        
        {
          data.map(({ avatar, name, review }, index) => {
            return (
              <SlideTestimonial key={index}>
                <ClientAvatar>
                  <img src={avatar} alt={name} />
                </ClientAvatar>
                <ClientName>{name}</ClientName>
                <ClientReview>{review}</ClientReview>
              </SlideTestimonial>
            )
          })
        }
        
        
      </TestimonialContainer>
    </section>
  )
}

export default Testimonial