import styled from 'styled-components'
import colors from '../../utils/style/colors'
import animation from '../../utils/style/animation'
import { StyledLink, StyledExternalLink } from '../../utils/style/atom' 

export const FooterContainer = styled.footer`
    background: ${colors.primary};
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
`
export const FooterLink = styled(StyledLink)`
    color: ${colors.bg};
`
export const FooterLogo = styled(FooterLink)`
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    display: block;
`
export const FooterPermalinks = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;

    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        flex-direction: column;
        gap: 1.5rem;
    }
`
export const FooterSocials = styled.div`
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;

    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        margin-bottom: 2.6rem;
    }
`
export const FooterSocialsLink = styled(StyledExternalLink)`
    background: ${colors.bg};
    color: ${colors.white};
    padding: 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
    transition: ${animation.transition};

    &:hover {
        background: transparent;
        color: ${colors.bg};
        border-color: ${colors.bg};
    }
`
export const FooterCopyright = styled.div`
    margin-bottom: 4rem;
    color: ${colors.bg};
`