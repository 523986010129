import styled from 'styled-components'
import { Container } from '../../utils/style/atom'
import colors from '../../utils/style/colors'
import animation from '../../utils/style/animation'

export const PortfolioContainer = styled(Container)`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;

    /* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
    @media screen  and (max-width: 1024px) {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }

    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
`
export const PortfolioItem = styled.article`
    background: ${colors.bg_variant};
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: ${animation.transition};

    &:hover {
        border-color: ${colors.primary_variant};
        background: transparent;
    }
`
export const PortfolioItemImage = styled.div`
    border-radius: 1.5rem;
    overflow: hidden;
`
export const PortfolioItemTitle = styled.h3`
    margin: 1.2rem 0 2rem;
`
export const PortfolioItemCta = styled.div`
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
`