import styled from 'styled-components'
import colors from '../../utils/style/colors';
import size from '../../utils/style/size';
import { Swiper, SwiperSlide } from 'swiper/react';

export const TestimonialContainer = styled(Swiper)`
    margin: 0 auto;
    width: 40%;
    padding-bottom: 4rem;

    /* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
    @media screen  and (max-width: 1024px) {
        width: 60%;
    }

    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {
        width: ${size.container_width_sm};
    }
`
export const SlideTestimonial = styled(SwiperSlide)`
    background: ${colors.bg_variant};
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
`
export const ClientAvatar = styled.div`
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid ${colors.primary_variant};
`
export const ClientReview = styled.small`
    color: ${colors.light};
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;

    /* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
    @media screen  and (max-width: 600px) {    
        width: ${size.container_width_sm};
    }
`
export const ClientName = styled.h5`
    
`