import React from 'react'
import { BsLinkedin} from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'
import { FiDribbble } from 'react-icons/fi'
import { SocialsHeader } from './styles'
import { StyledExternalLink } from '../../utils/style/atom'

const HeaderSocials = () => {
  return (
    <nav role="navigation" aria-label='Navigation réseaux sociaux'>
      <SocialsHeader>
          <StyledExternalLink href="https://www.linkedin.com/in/michael-portelas" target="_blank" rel="noopener noreferrer" aria-label="Lien vers profile linkdin"><BsLinkedin /></StyledExternalLink>
          <StyledExternalLink href="https://github.com/MichaelPortelas" target="_blank" rel="noopener noreferrer" aria-label="Lien vers profile github"><FaGithub /></StyledExternalLink>
          <StyledExternalLink href="https://dribbble.com/michael_portelas"  target="_blank" rel="noopener noreferrer" aria-label="Lien vers profile dribbble"><FiDribbble /></StyledExternalLink>
      </SocialsHeader>
    </nav>
  )
}

export default HeaderSocials