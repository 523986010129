import React from 'react'
import IMG1 from '../../assets/project/booki.jpg'
import IMG2 from '../../assets/project/ohmyfood.jpg'
import IMG3 from '../../assets/project/panthere.jpg'
import IMG4 from '../../assets/project/kanap.jpg'
import IMG5 from '../../assets/project/piiquante.jpg'
import IMG6 from '../../assets/project/groupomania.jpg'
import { 
  PortfolioContainer, 
  PortfolioItem,
  PortfolioItemImage,
  PortfolioItemTitle,
  PortfolioItemCta 
} from './styles'

import { ButtonExt, ButtonPrimaryExt } from '../../utils/style/atom'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Booki - (HTML - CSS)',
    github: 'https://github.com/MichaelPortelas/MichaelPortelas.github.io/tree/main/projet2',
    demo: 'https://michaelportelas.github.io/projet2/',
  },
  {
    id: 2,
    image: IMG2,
    title: 'OhMyFood - (SASS)',
    github: 'https://github.com/MichaelPortelas/MichaelPortelas.github.io/tree/main/projet3',
    demo: 'https://michaelportelas.github.io/projet3/index.html',
  },
  {
    id: 3,
    image: IMG3,
    title: 'La Panthère - (SEO, Accessibilité)',
    github: 'https://github.com/MichaelPortelas/MichaelPortelas.github.io/tree/main/New-projet4',
    demo: 'https://michaelportelas.github.io/New-projet4/',
  },
  {
    id: 4,
    image: IMG4,
    title: 'Kanap - (JavaScript)',
    github: 'https://github.com/MichaelPortelas/MichaelPortelas.github.io/tree/main/Projet5/front/js',
    demo: 'http://live-demo.portelas.fr/kanap/front/html/',
  },
  {
    id: 5,
    image: IMG5,
    title: 'Piiquante - (Node.js, Express)',
    github: 'https://github.com/MichaelPortelas/MichaelPortelas.github.io/tree/main/Projet6/backend',
    demo: 'http://live-demo.portelas.fr/piiquante',
  },
  {
    id: 6,
    image: IMG6,
    title: 'Groupomania - (React, Redux)',
    github: 'https://github.com/MichaelPortelas/P7-Groupomania',
    demo: 'http://groupomania-live-demo.portelas.fr/',
  }
]

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>Mes projets récents</h5>
      <h2>Portfolio</h2>

      <PortfolioContainer>
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <PortfolioItem key={id}>
                <PortfolioItemImage><img src={image} alt={title} /></PortfolioItemImage>
                <PortfolioItemTitle>{title}</PortfolioItemTitle>
                <PortfolioItemCta>
                  <ButtonExt href={github} target='_blank' rel="noreferrer">Github</ButtonExt>
                  {demo !== '' ? (
                    <ButtonPrimaryExt href={demo} target='_blank' rel="noreferrer">Live Demo</ButtonPrimaryExt>
                  ):(<></>)}
                </PortfolioItemCta>
              </PortfolioItem>
            )
          })
        }      
      </PortfolioContainer>
    </section>
  )
}

export default Portfolio